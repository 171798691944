import { PropsWithChildren } from 'react';
import Footer from './footer';
import MainContent from './main-content';
import TopNavigationBar from './top-navigation-bar';

const Layout: React.FC<PropsWithChildren> = ({ children }) => (
  <>
    <TopNavigationBar />
    <MainContent>{children}</MainContent>
    <Footer />
  </>
);

export default Layout;
