import { NextPage } from 'next';
import { ErrorProps } from 'next/error';
import Head from 'next/head';
import Layout from '../react-components/layout';

const statusCodes: { [code: number]: string } = {
  400: 'Bad request',
  404: 'Page not found',
  405: 'Method not allowed',
  500: 'Internal server error'
};

const ErrorPage: NextPage<ErrorProps> = ({ title, statusCode }) => {
  const message = title ?? statusCodes[statusCode] ?? 'An unexpected error occurred.';
  return (
    <Layout>
      <Head>
        <title>{statusCode} Error</title>
      </Head>
      <div className='flex items-center justify-center'>
        <h1 className='m-4 mb-0 md:mb-4 text-center md:inline-block md:mr-0 md:pr-4 md:border-r select-none'>
          {statusCode}
        </h1>
        <p className='m-4 text-center md:inline-block'>{message}</p>
      </div>
    </Layout>
  );
};

ErrorPage.getInitialProps = ({ res, err }): ErrorProps => ({
  statusCode: res?.statusCode ?? err?.statusCode ?? 500,
  title: res?.statusMessage ?? err?.message
});

export default ErrorPage;
